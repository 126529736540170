import * as React from 'react';
import {
	Stack,
	Grid,
	Typography, 
	Card, CardActionArea, CardContent,
	List, ListItem, ListItemText
} from '@mui/material';

const text = {
	fontSize: "0.9rem"
}

const Cards = ({reference}) => {

	return (
		<Grid container alignItems="stretch" spacing={2} sx={{marginTop: '2rem'}} ref={reference}>

			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div" sx={{borderBottom: '4px solid #e74c3c'}}>
							Nabla AI云计算平台
						</Typography>
						<List>
							{['基于可信人工智能技术框架和核心算法工具箱，提供可拓展高性能计算服务', '面向高校和研究院所的Nabla AI Lab: 集成Jupyter创作型开发环境，提供一站式数据科学和人工智能专业教学和科研辅助服务', '面向银行业金融机构的Nabla AI Fab：提供可信人工智能模型开发和远程模型验证等云原生软件及服务'].map((item, idx) => {
									return <ListItem key={idx}>
													<ListItemText
														primary={item}
														primaryTypographyProps={text}
													/>
												</ListItem>
								}
							)}
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div" sx={{borderBottom: '4px solid #e67e22'}}>
							DSAI教学工具箱
						</Typography>
						<List>
							{['面向大专院校数据科学(Data Science)和人工智能(Artifical Intelligence)专业的教学辅助', '与高校知名教授合作共建(DSAI)相关课程，优质教学资源共享', '提供一系列优质教程讲义，数据分析和建模案例', "协助大专院校组织策划DSAI建模大赛"].map((item, idx) => {
									return <ListItem key={idx}>
													<ListItemText
														primary={item}
														primaryTypographyProps={text}
													/>
												</ListItem>
								}
							)}
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div" sx={{borderBottom: '4px solid #2ecc71'}}>
							PiML可解释机器学习
						</Typography>
						<List>
							{['内在可解释机器学习模型设计', '任意黑盒模型的事后解释,测试验证和模型比较', '机器学习算法多维度风险评估', '低代码模式的机器学习工作流'].map((item, idx) => {
									return <ListItem key={idx}>
													<ListItemText
														primary={item}
														primaryTypographyProps={text}
													/>
												</ListItem>
								}
							)}
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div" sx={{borderBottom: '4px solid #3498db'}}>
							RMVP远程模型验证平台
						</Typography>
						<List>
							{['在严格保护算法源代码和商业机密的前提下，提供远程模型验证和算法评估服务', '支持按需进行模型验证和算法评估', '缩短模型开发生命周期', '支持已部署模型的远程持续监控和持续评估'].map((item, idx) => {
									return <ListItem key={idx}>
													<ListItemText
														primary={item}
														primaryTypographyProps={text}
													/>
												</ListItem>
								}
							)}
						</List>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)

}

export default Cards;