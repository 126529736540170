import React from 'react'
import {Container, Stack, Typography, Button, Grid} from '@mui/material';
import mtest from '../../images/mtest.png'

export default function Risk() {
  return (
		<div style={{padding: '10px 0px'}}>
			<Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'} }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<img src={mtest} style={{width: '100%'}} />
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={5}>
						<Typography variant='h5' sx={{color: "rgb(11,50,103)"}}>RMVP远程模型验证平台</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>在严格保护算法源代码和商业机密的前提下，提供远程模型验证和算法评估服务;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持按需进行模型验证和算法评估;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>缩短模型开发生命周期;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持已部署模型的远程持续监控和持续评估;</Typography>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="lg" sx={{ display: {xl: 'none', lg: 'block', sm: 'block', md: 'block', xs: 'none'} }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<img src={mtest} style={{width: '100%'}} />
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={5}>
						<Typography variant='h5' sx={{color: "rgb(11,50,103)"}}>RMVP远程模型验证平台</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>在严格保护算法源代码和商业机密的前提下，提供远程模型验证和算法评估服务;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持按需进行模型验证和算法评估;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>缩短模型开发生命周期;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持已部署模型的远程持续监控和持续评估;</Typography>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="xs" sx={{ display: {xl: 'none', lg: 'none', sm: 'none', md: 'none', xs: 'block'} }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='h5' sx={{color: "rgb(11,50,103)"}}>RMVP远程模型验证平台</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>在严格保护算法源代码和商业机密的前提下，提供远程模型验证和算法评估服务;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持按需进行模型验证和算法评估;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>缩短模型开发生命周期;</Typography>
						<Typography variant='subtitle1' sx={{mt: '10px', color: "#686868"}}>支持已部署模型的远程持续监控和持续评估;</Typography>
					</Grid>
					<Grid item xs={12}>
						<img src={mtest} style={{width: '100%'}} />
					</Grid>
				</Grid>
			</Container>
		</div>
  )
}
