import React from 'react'
import {Container, Stack, Typography, Divider, Grid} from '@mui/material';
import framework from '../../images/framework.png'

export default function Tech() {
  return (
		<div style={{padding: '50px 0px'}}>
			<Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'} }}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='h5'>可信人工智能技术框架</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>公平的, 透明的, 可解释的, 稳健的, 保障安全的, 尊重人权和隐私, 可问责的人工智能</Typography>
					</Grid>
					<Grid item xs={8}>
						<img src={framework} style={{width: '100%'}} />
					</Grid>
				</Grid>
				<Divider sx={{mt: '25px'}} />
			</Container>

			<Container maxWidth="lg" sx={{ display: {xl: 'none', lg: 'block', sm: 'block', md: 'block', xs: 'none'} }}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='h5' sx={{color: "rgb(11,50,103)"}}>可信人工智能技术框架</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>公平的, 透明的, 可解释的, 稳健的, 保障安全的, 尊重人权和隐私, 可问责的人工智能</Typography>
					</Grid>
					<Grid item xs={8}>
						<img src={framework} style={{width: '100%'}} />
					</Grid>
				</Grid>
				<Divider sx={{mt: '25px'}} />
			</Container>

			<Container maxWidth="xs" sx={{ display: {xl: 'none', lg: 'none', sm: 'none', md: 'none', xs: 'block'} }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='h5' sx={{color: "rgb(11,50,103)"}}>可信人工智能技术框架</Typography>
						<Typography variant='subtitle1' sx={{mt: '20px', color: "#686868"}}>公平的, 透明的, 可解释的, 稳健的, 保障安全的, 尊重人权和隐私, 可问责的人工智能</Typography>
					</Grid>
					<Grid item xs={12}>
						<img src={framework} style={{width: '100%'}} />
					</Grid>
				</Grid>
				<Divider sx={{mt: '25px'}} />
			</Container>
		</div>
  )
}
