import * as React from 'react';
import {Container, Stack, Typography, Box, Button, Card, CardActionArea, CardContent, Divider} from '@mui/material';
import Cards from './Card';
import MobileCards from '../mobile/Cards'
import MidCards from '../mobile/MidCards'
import Contact from './Contact';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import bg from '../../images/bg.png'
import Tech from './Tech';
import Risk from './Risk';
import Partner from './Partner';

const Main = ({reference}) => {

	return (
		<>
			<Box sx={{backgroundImage: `url(${bg})`, backgroundSize: '150rem', height: '20rem', padding: '100px 0px 120px 0px', display: {xl: 'block', xs: 'none'}}}>
				<Stack direction="column" alignItems="center" spacing={2}>
					<Typography variant='h2'><b>纳布拉科技, 2022启航</b></Typography>
					<Typography variant='h4'>安全, 可信, 负责任的人工智能</Typography>
				</Stack>
			</Box>
			<Box sx={{backgroundImage: `url(${bg})`, backgroundSize: '50rem', height: '10rem', padding: '80px 0px 40px 0px', display: {lg: 'block', xl: 'none', sm: 'block', md: 'block', xs: 'block'}}}>
				<Stack direction="column" alignItems="center" spacing={2}>
					<Container maxWidth="xs">
						<Typography variant='h4'><b>纳布拉科技, 2022启航</b></Typography>
						<Typography variant='h5'>安全, 可信, 负责任的人工智能</Typography>
					</Container>
				</Stack>
			</Box>
			<Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'}}}>
				<Cards reference={reference} />
				<Divider sx={{mt: '50px'}} />
			</Container>
			<Container maxWidth="lg" sx={{ display: {lg: 'block', xl: 'none', sm: 'none', md: 'none', xs: 'none'}}}>
				<Cards reference={reference} />
				<Divider sx={{mt: '50px'}} />
			</Container>
			<Container maxWidth="md" sx={{ display: {lg: 'none', xl: 'none', sm: 'block', md: 'block', xs: 'none'}}}>
				<MidCards reference={reference} />
				<Divider sx={{mt: '50px'}} />
			</Container>
			<Container maxWidth="xs" sx={{ display: {lg: 'none', xl: 'none', sm: 'none', md: 'none', xs: 'block'}}}>
				<MobileCards reference={reference} />
				<Divider sx={{mt: '50px'}} />
			</Container>
			{/* <Partner /> */}
			<Tech />
			<Risk />
			<Contact />
		</>
	)

}

export default Main;