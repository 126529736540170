import React from 'react'
import {Container, Stack, Typography, Button} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function Contact() {
  return (
    <div style={{marginTop: '50px', padding: '50px 0px', backgroundColor: '#F7F7F7'}}>
			<Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'} }}>
				<Stack>
					<Typography variant='h2'>可信人工智能,</Typography>
					<Typography variant='h2'>就找纳布拉科技</Typography>
					<Stack direction="row" spacing={1} alignItems="center" sx={{mt: '40px'}}>
						<Button variant="outlined" sx={{mr: '30px'}}>联系我们的专家</Button>
						<LocalPhoneIcon />
						<Typography
							variant="subtitle1"
							noWrap
							component="div"
							sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
						>
							13006696218
						</Typography>
					</Stack>
				</Stack>
			</Container>

			<Container maxWidth="lg" sx={{ display: {xl: 'none', lg: 'block', sm: 'block', md: 'block', xs: 'none'} }}>
				<Stack>
					<Typography variant='h4'>可信人工智能,</Typography>
					<Typography variant='h4'>就找纳布拉科技</Typography>
					<Stack direction="row" spacing={1} alignItems="center" sx={{mt: '40px'}}>
						<Button variant="outlined" sx={{mr: '30px'}}>联系我们的专家</Button>
						<LocalPhoneIcon />
						<Typography
							variant="subtitle1"
							noWrap
							component="div"
							sx={{ mr: 2 }}
						>
							13006696218
						</Typography>
					</Stack>
				</Stack>
			</Container>

			<Container maxWidth="xs" sx={{ display: {xl: 'none', lg: 'none', sm: 'none', md: 'none', xs: 'block'} }}>
				<Stack>
					<Typography variant='h4'>可信人工智能,</Typography>
					<Typography variant='h4'>就找纳布拉科技</Typography>
					<Stack direction="row" spacing={1} alignItems="center" sx={{mt: '40px'}}>
						<Button variant="outlined" sx={{mr: '30px'}}>联系我们的专家</Button>
						<LocalPhoneIcon />
						<Typography
							variant="subtitle1"
							noWrap
							component="div"
							sx={{ mr: 2 }}
						>
							13006696218
						</Typography>
					</Stack>
				</Stack>
			</Container>
    </div>
  )
}
