import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import logo from '../../images/logo.svg';

import {
  Stack, Box, Container,
  AppBar, Toolbar,
  Typography,
  Button, IconButton,
  Menu, MenuItem,
  Icon,
  Link
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const pages = ['愿景与使命', 'AI监管时代已来', 'AI系统生命周期', '可信人工智能', '模型风险管理', '产品与服务', '教育与培训', '技术博客', '关于我们'];
const pages = ['首页', '产品与服务', '技术博客', '登录'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = ({executeScroll}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElProduct, setAnchorElProduct] = React.useState(null);
  const open = Boolean(anchorElProduct);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavProduct = (event) => {
    setAnchorElProduct(event.currentTarget);
  };

  const handleCloseNavProduct = () => {
    setAnchorElProduct(null);
  };

  return (
    <AppBar position="static" color="appBar">
      <Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'} }}>
        <Toolbar disableGutters>
          <img src={logo} style={{width: "24px", marginRight: '10px'}} />
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <b>纳布拉科技</b>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            纳布拉科技
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, idx) => (
              page === "产品与服务" ?
              <>
                <Button
                  key={page}
                  // onClick={handleOpenNavProduct}
                  onClick={executeScroll}
                  sx={{ my: 2, mr: 2, color: 'black'}}
                  // endIcon={<KeyboardArrowDownIcon />}
                >
                  {page}
                </Button>
              </>
              :
              page === "登录"?
              <Button
                key={page}
                // onClick={handleOpenNavProduct}
                sx={{ my: 2, mr: 2, color: 'black', display: 'block' }}
              >
                <Link href="https://ailab.nablatech.cn" underline="none" color="black" target="_blank">{page}</Link>
              </Button>
              :
              <Button
                key={page}
                // onClick={handleOpenNavProduct}
                sx={{ my: 2, mr: 2, color: 'black', display: 'block' }}
              >
                {page}
              </Button>
            ))}
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorElProduct}
              open={open}
              onClose={handleCloseNavProduct}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseNavProduct}>Nabla AI Lab</MenuItem>
              <MenuItem onClick={handleCloseNavProduct}>大规模ML训练调参</MenuItem>
              <MenuItem onClick={handleCloseNavProduct}>模型验证平台</MenuItem>
              <MenuItem onClick={handleCloseNavProduct}>可信测试可视化</MenuItem>
            </Menu> */}
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* <Stack direction="row" spacing={1} alignItems="center">
            <LocalPhoneIcon />
            <Typography
              variant="subtitle1"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              00000000000
            </Typography>
          </Stack> */}

        </Toolbar>
      </Container>

      <Container maxWidth="lg" sx={{ display: {xl: 'none', lg: 'block'} }}>
        <Toolbar disableGutters>
          <img src={logo} style={{display: 'flex', justifyContent: "space-between", width: "24px", marginRight: '10px'}} />
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <b>纳布拉科技</b>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', justifyContent: "space-between", md: 'none' } }}
          >
            纳布拉科技
          </Typography>

          <Box sx={{ flexGrow: 1, justifyContent: "space-between", flow: 'right', display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          

          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, idx) => (
              page === "产品与服务" ?
              <>
                <Button
                  key={page}
                  // onClick={handleOpenNavProduct}
                  onClick={executeScroll}
                  sx={{ my: 2, mr: 2, color: 'black'}}
                  // endIcon={<KeyboardArrowDownIcon />}
                >
                  {page}
                </Button>
              </>
              :
              page === "登录"?
              <Button
                key={page}
                // onClick={handleOpenNavProduct}
                sx={{ my: 2, mr: 2, color: 'black', display: 'block' }}
              >
                <Link href="https://ailab.nablatech.cn" underline="none" color="black" target="_blank">{page}</Link>
              </Button>
              :
              <Button
                key={page}
                // onClick={handleOpenNavProduct}
                sx={{ my: 2, mr: 2, color: 'black', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
