import ResponsiveAppBar from "./components/layouts/AppBar";
import Main from './components/main/Main'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from "./components/layouts/Footer";
import { useRef } from 'react';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    appBar: {
      main: '#F8FAFC'
    },
    font: {
      main: 'black'
    },
    footerFont: {
      main: '#64748B'
    }
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});

function App() {
  
	const myRef = useRef(null)

	const executeScroll = () => myRef.current.scrollIntoView()

  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        <ResponsiveAppBar executeScroll={executeScroll} />
        <Main reference={myRef} />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
