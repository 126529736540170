import React from 'react'
import {Container, Stack, Typography, Grid, Divider} from '@mui/material';

export default function Footer() {
  return (
		<div style={{paddingTop: '10px', paddingBottom: '50px', backgroundColor: "#2c3e50"}}>
			<Container maxWidth="xl" sx={{ display: {xl: 'block', lg: 'none', sm: 'none', md: 'none', xs: 'none'} }}>
				<Divider />
				<Grid container sx={{pt: '40px'}}>
					<Grid item xs={6}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>纳布拉科技</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7', marginTop: '15px'}}>版权所有 © 2022 纳布拉科技（深圳）有限公司</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>Email：contact@nablatech.cn</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>用户协议 隐私条款</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7', marginTop: '15px'}}><a href='https://beian.miit.gov.cn'>粤ICP备2021155340号</a></Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>粤公网安备 44030402005613号</Typography>
						</Stack>
					</Grid>
					<Grid item xs={2}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>产品与服务</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>Nabla AI云计算平台</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>DSAI教学工具箱</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>PiML可解释机器学习</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>RMVP远程模型验证平台</Typography>
						</Stack>
					</Grid>
					<Grid item xs={2}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>行业</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>金融</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>高校</Typography>
						</Stack>
					</Grid>
					{/* <Grid item xs={2}>
						<Typography variant='h6'>合作伙伴</Typography>
						<Typography variant='subtitle2' style={{color: '#64748B'}}>高校</Typography>
						<Typography variant='subtitle2' style={{color: '#64748B'}}>企业</Typography>
					</Grid> */}
					<Grid item xs={2}>
						<Stack direction="column" sx={{pb: '50px'}}>
							<Typography variant='h6' sx={{color: 'white'}}>关于纳不拉科技</Typography>
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>公司简介</Typography> */}
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>管理团队</Typography> */}
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>新闻与咨询</Typography> */}
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>技术研究</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>招贤纳士</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>联系我们</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="lg" sx={{ display: {xl: 'none', lg: 'block', sm: 'block', md: 'block', xs: 'none'} }}>
				<Divider />
				<Grid container sx={{pt: '40px'}}>
					<Grid item xs={6}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>纳布拉科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7', marginTop: '15px'}}>版权所有 © 2022 纳布拉科技（深圳）有限公司</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>Email：contact@nablatech.cn</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>用户协议 隐私条款</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7', marginTop: '15px'}}><a href='https://beian.miit.gov.cn'>粤ICP备2021155340号</a></Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>粤公网安备 44030402005613号</Typography>
						</Stack>
					</Grid>
					<Grid item xs={2}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>产品与服务</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>Nabla AI云计算平台</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>DSAI教学工具箱</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>PiML可解释机器学习</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>RMVP远程模型验证平台</Typography>
						</Stack>
					</Grid>
					<Grid item xs={2}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>行业</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>金融</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>高校</Typography>
						</Stack>
					</Grid>
					{/* <Grid item xs={2}>
						<Typography variant='h6'>合作伙伴</Typography>
						<Typography variant='subtitle2' style={{color: '#64748B'}}>高校</Typography>
						<Typography variant='subtitle2' style={{color: '#64748B'}}>企业</Typography>
					</Grid> */}
					<Grid item xs={2}>
						<Stack direction="column" sx={{pb: '50px'}}>
							<Typography variant='h6' sx={{color: 'white'}}>关于纳布拉科技</Typography>
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>公司简介</Typography> */}
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>管理团队</Typography> */}
							{/* <Typography variant='subtitle2' style={{color: '#64748B'}}>新闻与咨询</Typography> */}
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>技术研究</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>招贤纳士</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>联系我们</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="xs" sx={{ display: {xl: 'none', lg: 'none', sm: 'none', md: 'none', xs: 'block'} }}>
				<Divider />
				<Grid container sx={{pt: '40px'}}>
					<Grid item xs={12}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>纳布拉科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7', marginTop: '15px'}}>版权所有 © 2022 纳布拉科技（深圳）有限公司</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>Email：contact@nablatech.cn</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>用户协议 隐私条款</Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7', marginTop: '15px'}}><a href='https://beian.miit.gov.cn'>粤ICP备2021155340号</a></Typography>
							<Typography variant='subtitle2' component="div" style={{color: '#bdc3c7'}}>粤公网安备 44030402005613号</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sx={{pt: '40px'}}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>产品与服务</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>Nabla AI云计算平台</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>DSAI教学工具箱</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>PiML可解释机器学习</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>RMVP远程模型验证平台</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sx={{pt: '40px'}}>
						<Stack direction="column">
							<Typography variant='h6' sx={{color: 'white'}}>行业</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>金融</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>高校</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sx={{pt: '40px'}}>
						<Stack direction="column" sx={{pb: '50px'}}>
							<Typography variant='h6' sx={{color: 'white'}}>关于纳布拉科技</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>技术研究</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>招贤纳士</Typography>
							<Typography variant='subtitle2' style={{color: '#bdc3c7'}}>联系我们</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</div>
  )
}
